<template>
  <div class="lf-body">
    <div class="lf-left"><img style="height: 100%;" draggable="false" src="@/assets/images/login-form.png" alt=""></div>
    <div class="lf-right">
      <el-tabs v-model="activeTab">
        <el-tab-pane label="快捷登录" name="phone">
          <div class="content-panel">
            <el-form :model="phoneForm" :rules="phoneRules" ref="phoneForm">
              <el-form-item prop="phoneNo">
                <el-input placeholder="请输入手机号码" v-model="phoneForm.phoneNo"></el-input>
              </el-form-item>
              <el-form-item style="border: 1px solid #DCDFE6;border-radius: 4px;">
                <drag-verify
                 class="hhs"
                  ref="dragVerify"
                  :isPassing="isPassing"
                  :width="300"
                  text="请拖动滑块到最右边"
                  successText="验证通过"
                  background="#ccc"
                  completedBg="rgb(0, 255, 0)"
                  handlerIcon="el-icon-d-arrow-right"
                  successIcon="el-icon-circle-check"
                  @passcallback="passCallback"
                >
                </drag-verify>
              </el-form-item>
              <el-form-item prop="code">
                <el-input placeholder="短信验证码" v-model="phoneForm.code">
                  <template #append>
                    <el-link type="primary" href="javascript:void(0)" :disabled="!isCodeEnable" @click="startCountdown">
                      获取验证码
                    </el-link>
                    <vue-countdown :time="time" v-slot="{ seconds }" v-if="counting" :autoStart="true"
                                   @end="onCountdownEnd">
                      （{{ seconds }} 秒）
                    </vue-countdown>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item class="dis-sta-cen">
                <el-checkbox v-model="phoneForm.isAgree"></el-checkbox>
                <span style="margin-left: 10px;">我已经阅读并同意E查工程<span @click="agreementClick()" style="color: #5D6FE9;cursor: pointer;">《隐私政策》</span></span>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" class="login-btn" @click="handlePhoneConfirm('phoneForm')"
                           :disabled="!phoneForm.isAgree">
                  登录/注册
                </el-button>
              </el-form-item>
              <div><span style="font-size:12px">未注册的手机号验证后将自动创建新账号</span></div>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="密码登录" name="pass">
          <div class="content-panel">
            <el-form :model="passForm" :rules="passRules" ref="passForm">
              <el-form-item prop="userName">
                <el-input placeholder="请输入用户名" v-model="passForm.userName"></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input placeholder="请输入密码" show-password v-model="passForm.password"></el-input>
              </el-form-item>
              <el-form-item>
                <el-checkbox v-model="passForm.isAgree"></el-checkbox>
                <span style="margin-left: 10px;">我已经阅读并同意E查工程<span @click="agreementClick()" style="color: #5D6FE9;cursor: pointer;">《隐私政策》</span></span>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" class="login-btn" @click="handlePassConfirm('passForm')"
                           :disabled="!passForm.isAgree">
                  登录/注册
                </el-button>
              </el-form-item>
              <div class="register-forget">
                <el-link href="/register">免费注册</el-link>
                <el-link href="/forget-password">忘记密码？</el-link>
              </div>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane v-if="appid" label="扫码登录" name="qr">
          <div class="content-panel" v-if="!isNewPeople">
            <wxlogin style="width: 200px;height: 300px;background-color: #fff;" :appid="appid" self_redirect="true" :scope="scope" :redirect_uri="redirect_uri" :href="href" :state="state"></wxlogin>
          </div>
          <div v-else>
            <login-new-user :openid="openid" :unionId="unionId" :redirectPath="redirect_path" @closeLoginEmit="thisCloseLoginEmit"></login-new-user>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { forwardBID } from '@/api/forward.js'
// import { getByTel } from '@/api/user.js'
import {
  getIp,
  getByTel,
  getUserInfoByIdToken,
  getCompanyInfoByCode,
  getWxLoginParam
} from '@/api/user.js'
import LoginNewUser from '@/components/login-new-user/login-new-user'
import VueCountdown from '@chenfengyuan/vue-countdown'
import DragVerify from '../vue-drag-verify/dragVerify'
import wxlogin from 'vue-wxlogin'
import { computed } from 'vue'
import { mapGetters } from 'vuex'
export default {
  name: 'login-form',
  props: {
    pathName: String // 登录后跳转URI
  },
  components: {
    DragVerify,
    VueCountdown,
    wxlogin,
    LoginNewUser
  },
  data () {
    var validatePhoneNo = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号码不能为空！'))
      }
      const regex = /^1[3456789]\d{9}$/
      if (!regex.test(value)) {
        callback(new Error('请输入正确的手机号码！'))
      } else {
        callback()
      }
    }
    return {
      isNewPeople: false,
      activeTab: 'phone',
      phoneForm: {
        phoneNo: '',
        code: '',
        isSlider: false,
        isAgree: false
      },
      phoneRules: {
        phoneNo: [
          {
            required: true,
            validator: validatePhoneNo,
            trigger: 'change'
          }
        ],
        code: [
          {
            required: true,
            message: '请输入手机验证码！',
            trigger: 'change'
          }
        ]
      },
      passForm: {
        userName: '',
        password: '',
        isAgree: false
      },
      passRules: {
        userName: [{
          required: true,
          message: '用户名不能为空！',
          trigger: 'change'
        }],
        password: [{
          required: true,
          message: '密码不能为空',
          trigger: 'change'
        }]
      },
      userInfo: null,
      mobile: '',
      time: 60 * 1000,
      counting: false, // 倒计时
      isPassing: false, // 滑动验证是否通过
      isCodeEnable: false, // 验证码获取功能是否可用
      appid: '',
      scope: 'snsapi_login',
      // redirect_uri: 'https%3A%2F%2Fwww.yijingjia.com%2F',
      redirect_uri: '',
      href: '',
      state: new Date().getTime().toString(),
      redirect_path: '',
      openid: '',
      unionId: '',
      myCustomerServiceTel: ''
    }
  },
  computed: {
    ...mapGetters([
      'customerServiceTel'
    ]),
    customerServiceTelChange: function () {
      const vm = this
      // console.log('计算属性: ' + JSON.parse(vm.vipData).vipLevel)
      if (vm.customerServiceTel) {
        return vm.customerServiceTel
      } else {
        return null
      }
    }
  },
  watch: {
    customerServiceTelChange: {
      immediate: true,
      handler: function (newValue, oldValue) {
        this.myCustomerServiceTel = newValue
      }
    }
  },
  mounted () {
    // newUser: 新用户 loginSuccess: 登录成功 loginError: 登录失败 logining: 登录中
    const vm = this
    vm.isNewPeople = false
    window.addEventListener('message', function (e) {
      const data = e.data
      vm.openid = data.openid
      vm.unionId = data.unionId
      const state = data.state
      console.log(data.openid + ' --- ' + data.unionId + ' --- ' + state)
      if (state === 'newUser') {
        vm.isNewPeople = true
      } else if (state === 'loginSuccess') {
        if (vm.$route.path === '/login') {
          vm.$router.push({ path: '/index' })
          setTimeout(function () {
            vm.$router.go(0)
          }, 500)
        } else {
          vm.$router.go(0)
        }
      } else if (state === 'disable') {
        vm.thisCloseLoginEmit()
        vm.$store.dispatch('logout')
        vm.isLogin = false
        vm.$router.push({ name: 'index' })
        vm.$alert('<span class="icon iconfont" style="font-size: 23px;color: orange;vertical-align: middle;">&#xe713;</span>  您的账号已经被冻结.请联系客服 <span style="color: #5D6FE9;">' + vm.myCustomerServiceTel + '</span>', '温馨提示', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定'
        })
      } else if (state === 'loginError') {
        vm.thisCloseLoginEmit()
        vm.$store.dispatch('logout')
        vm.isLogin = false
        vm.$router.push({ name: 'index' })
        vm.$alert('<span class="icon iconfont" style="font-size: 23px;color: orange;vertical-align: middle;">&#xe713;</span>  账号存在异常,请联系客服 <span style="color: #5D6FE9;">' + vm.myCustomerServiceTel + '</span>', '温馨提示', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定'
        })
      }
    }, false)
    if (this.$route.query && this.$route.query.redirect) {
      this.redirect_path = this.$route.query.redirect
    } else {
      this.redirect_path = this.path
    }
    this.initWxLoginParam()
  },
  methods: {
    openComponent () {
      const vm = this
      vm.state = new Date().getTime().toString()
      if (vm.$refs.phoneForm) vm.$refs.phoneForm.resetFields()
      if (vm.$refs.passForm) vm.$refs.passForm.resetFields()
      if (this.$route.query && this.$route.query.redirect) {
        this.redirect_path = this.$route.query.redirect
      } else {
        this.redirect_path = this.path
      }
      this.initWxLoginParam()
    },
    closeComponent () {
      const vm = this
      vm.isNewPeople = false
      vm.activeTab = 'phone'
      vm.phoneForm = {
        phoneNo: '',
        code: '',
        isSlider: false,
        isAgree: false
      }
      vm.passForm = {
        userName: '',
        password: '',
        isAgree: false
      }
      vm.userInfo = null
      vm.mobile = ''
      vm.counting = false // 倒计时
      vm.isPassing = false // 滑动验证是否通过
      vm.isCodeEnable = false // 验证码获取功能是否可用
      vm.appid = ''
      vm.redirect_uri = ''
      vm.href = ''
      vm.state = new Date().getTime().toString()
      vm.redirect_path = ''
      vm.openid = ''
      vm.unionId = ''
      if (vm.$refs.phoneForm) vm.$refs.phoneForm.resetFields()
      if (vm.$refs.passForm) vm.$refs.passForm.resetFields()
    },
    // 用户信息存放缓存中
    saveUserInfoToSession () {
      const that = this
      const resultData = that.userInfo
      getIp()
        .then(result => {
          if (result.code === 0) {
            const arr = result.data.split(',')
            resultData.ip = arr[0]
          } else {
            resultData.ip = '127.0.0.1'
          }
          that.$store.dispatch('setUserInfo', JSON.stringify(resultData))
        })
        .catch(error => {
          console.log(error)
        })
      const params = {
        tel: that.mobile
      }
      getByTel(params)
        .then(result => {
          if (result.code === 0) {
            if (result.data.enableStatus === 0) {
              resultData.openId = result.data.openId
              that.$store.dispatch('setVipData', JSON.stringify(result.data))
            } else {
              that.$store.dispatch('logout')
              that.isLogin = false
              that.$router.push({ name: 'index' })
              return
            }
          } else {
            resultData.openId = ''
          }
          that.$store.dispatch('setUserInfo', JSON.stringify(resultData))
        })
        .catch(error => {
          console.log(error)
        })
      getCompanyInfoByCode({
        code: resultData.taxpayerNumber
      }).then(result => {
        const res = result.data
        resultData.companyId = res.data?.id
        that.$store.dispatch('setUserInfo', JSON.stringify(resultData))
      }).catch(error => {
        console.log(error)
      })
    },
    handleTabClick (tab, event) {
      console.log(tab, event)
    },
    handlePhoneConfirm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log('valid:' + valid)
          const that = this
          that.$store.dispatch('logout')
          const data = {
            dateBackgroundUrl: '/bid/user/loginValidationCode', // 验证码登录
            dateBackgroundRequestType: 'post',
            data: {
              mobile: that.phoneForm.phoneNo,
              verifyCode: that.phoneForm.code
            }
          }
          forwardBID(data)
            .then(result => {
              if (result.code === 0) {
                const idToken = result.data.idToken
                console.log(idToken)
                if (idToken) {
                  that.$store.dispatch('setIdToken', idToken)
                }
                that.getIdtokenUserInfo(idToken)
              } else {
                // 新用户去注册
                that.setRegister()
              }
            }).catch(error => {
              console.log(error)
              // ElMessage.error({ message: error, offset: 60 })
            })
        } else {
          console.log('valid:' + valid)
          return false
        }
      })
    },
    setRegister () {
      const that = this
      const data = {
        dateBackgroundUrl: '/user/user/loginBySMS', // 获取用户信息
        dateBackgroundRequestType: 'post',
        data: {
          mobile: that.phoneForm.phoneNo,
          verifyCode: that.phoneForm.code,
          type: '30'
        }
      }
      forwardBID(data)
        .then(result => {
          if (result.code === 0) {
            const idToken = result.data.idToken
            if (idToken) {
              that.$store.dispatch('setIdToken', idToken)
            }
            // 通过tokenId获取用户信息，有则返回，没有则新增
            getUserInfoByIdToken({
              idToken: idToken,
              openId: '',
              unionId: ''
            }).then(result => {
            }).catch(error => {
              console.log(error)
              // ElMessage.error({ message: error, offset: 60 })
            })
            that.getIdtokenUserInfo(idToken)
          }
        })
        .catch(error => {
          console.log(error)
          // ElMessage.error({ message: error, offset: 60 })
        })
    },
    getIdtokenUserInfo (idToken) {
      const that = this
      const data = {
        dateBackgroundUrl: '/bid/user/accountInfo', // 获取用户信息
        dateBackgroundRequestType: 'post',
        data: {
          idToken: idToken
        }
      }
      forwardBID(data)
        .then(result => {
          if (result.code === 0) {
            const resultData = result.data
            that.userInfo = resultData
            that.mobile = resultData.mobile
            const params = {
              tel: that.mobile
            }
            getByTel(params).then(result => {
              if (result.code === 0) {
                if (result.data.enableStatus === 0) {
                  that.saveUserInfoToSession()
                  ElMessage.success({ message: '登录成功！', offset: 60 })
                  if (this.redirect_path === '') {
                    // 路径为空不跳转，直接关闭
                    // this.$router.push({ name: 'index' })
                  } else {
                    const path = this.redirect_path
                    this.$router.push({ path: path })
                  }
                  this.phoneForm.phoneNo = ''
                  this.phoneForm.code = ''
                  this.phoneForm.isSlider = false
                  this.phoneForm.isAgree = false
                  this.passForm.userName = ''
                  this.passForm.password = ''
                  this.passForm.isAgree = false
                  this.$refs.phoneForm.resetFields()
                  this.$refs.passForm.resetFields()
                  this.closeLoginEmit()
                } else {
                  window.postMessage({
                    openid: '',
                    unionId: '',
                    state: 'disable' // newUser: 新用户 loginSuccess: 登录成功 loginError: 登录失败 logining: 登录中 disable: 禁用
                  }, '*')
                }
              }
            }).catch(error => {
              console.log(error)
            })
          } else {
            ElMessage.error({ message: result.msg, offset: 60 })
          }
        })
        .catch(error => {
          console.log(error)
          // ElMessage.error({ message: error, offset: 60 })
        })
    },
    handlePassConfirm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const that = this
          that.$store.dispatch('logout')
          const data = {
            dateBackgroundUrl: '/bid/user/login', // 用户名密码登录
            dateBackgroundRequestType: 'post',
            data: {
              loginName: that.passForm.userName,
              password: that.passForm.password
            }
          }
          forwardBID(data).then(result => {
            if (result.code === 0) {
              const idToken = result.data.idToken
              if (idToken) {
                that.$store.dispatch('setIdToken', idToken)
              }
              // 通过tokenId获取用户信息，有则返回，没有则新增
              getUserInfoByIdToken({
                idToken: idToken,
                openId: '',
                unionId: ''
              }).then(result => {
              }).catch(error => {
                console.log(error)
                // ElMessage.error({ message: error, offset: 60 })
              })
              const data = {
                dateBackgroundUrl: '/bid/user/accountInfo', // 获取用户信息
                dateBackgroundRequestType: 'post',
                data: {
                  idToken: idToken
                }
              }
              forwardBID(data)
                .then(result => {
                  if (result.code === 0) {
                    const resultData = result.data
                    that.userInfo = resultData
                    that.mobile = resultData.mobile
                    const params = {
                      tel: that.mobile
                    }
                    getByTel(params)
                      .then(result => {
                        if (result.code === 0) {
                          if (result.data.enableStatus === 0) {
                            that.saveUserInfoToSession()
                            ElMessage.success({ message: '登录成功！', offset: 60 })
                            if (this.path === '') {
                              // 路径为空不跳转，直接关闭
                              // this.$router.push({ name: 'index' })
                            } else {
                              const path = this.redirect_path
                              this.$router.push({ path: path })
                            }
                          } else {
                            that.$store.dispatch('logout')
                            that.isLogin = false
                            that.$router.push({ name: 'index' })
                            that.$alert('<span class="icon iconfont" style="font-size: 23px;color: orange;vertical-align: middle;">&#xe713;</span>  您的账号已经被冻结.请联系客服 <span style="color: #5D6FE9;">400-8885449</span>', '温馨提示', {
                              dangerouslyUseHTMLString: true,
                              confirmButtonText: '确定'
                            })
                          }
                        } else {
                          resultData.openId = ''
                        }
                        this.phoneForm.phoneNo = ''
                        this.phoneForm.code = ''
                        this.phoneForm.isSlider = false
                        this.phoneForm.isAgree = false
                        this.passForm.userName = ''
                        this.passForm.password = ''
                        this.passForm.isAgree = false
                        this.$refs.phoneForm.resetFields()
                        this.$refs.passForm.resetFields()
                        this.closeLoginEmit()
                      })
                      .catch(error => {
                        console.log(error)
                      })
                  } else {
                    // console.log(error)
                    ElMessage.error({ message: result.msg, offset: 60 })
                  }
                })
                .catch(error => {
                  console.log(error)
                  // ElMessage.error({ message: error, offset: 60 })
                })
            } else {
              ElMessage.error({ message: result.msg, offset: 60 })
            }
          }).catch(error => {
            console.log(error)
            // ElMessage.error({ message: error, offset: 60 })
          })
        } else {
          return false
        }
      })
    },
    getVerifyCode () {
      const data = {
        dateBackgroundUrl: '/bid/verifyCode/registergetVerifyCode',
        dateBackgroundRequestType: 'post',
        data: {
          mobile: this.phoneForm.phoneNo,
          verifyTypeCode: '30'
        }
      }
      forwardBID(data)
        .then(result => {
        })
        .catch(error => {
          console.log(error)
        })
    },
    onCountdownEnd () {
      this.time = 60 * 1000
      this.counting = false
      this.isCodeEnable = true
    },
    startCountdown () {
      this.$refs.phoneForm.validateField('phoneNo', (error) => {
        if (!error) {
          this.isCodeEnable = false
          this.counting = true
          this.getVerifyCode()
        }
      })
    },
    passCallback () {
      this.isCodeEnable = true
    },
    agreementClick () {
      const vm = this
      sessionStorage.removeItem('about-item')
      sessionStorage.setItem('about-item', 'privacyPolicy')
      const routeData = vm.$router.resolve({
        name: 'about'
      })
      console.log(1111)
      window.open(routeData.href, '_blank')
    },
    initWxLoginParam () {
      const vm = this
      getWxLoginParam({}).then(result => {
        if (result.code === 0) {
          const resultData = result.data
          vm.appid = resultData.appID
          vm.redirect_uri = encodeURIComponent(resultData.redirectUri)
          vm.href = resultData.href
        } else {
          ElMessage.error({ message: result.msg, offset: 60 })
        }
      }).catch(error => {
        console.log(error)
        // ElMessage.error({ message: error, offset: 60 })
      })
    },
    thisCloseLoginEmit () {
      const vm = this
      vm.isNewPeople = false
      vm.closeLoginEmit()
    }
  },
  setup (props, { emit }) {
    const path = computed(() => {
      return props.pathName
    })
    function closeLoginEmit () {
      console.log('子组件：')
      emit('closeLoginEmit', 'login-form')
    }
    return {
      path, closeLoginEmit
    }
  }
}
</script>

<style scoped>
.lf-left {
  width: 365px;
  height: 452px;
}

.lf-right {
  width: 400px;
  height: 452px;
  margin: 40px;
}

.lf-body {
  padding: 0;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 766px;
  height: 451px;
  overflow: hidden;
  background: #fff;
}

.content-panel {
  margin: 10px;
}

.login-btn {
  width: 100%;
}

.register-forget {
  display: flex;
  justify-content: space-between;
}
/deep/ .el-tabs__nav-wrap::after {
  height: 0;
}
/deep/ .el-dialog__header {
  padding: 0 !important;
}
iframe{
  height: 300px;
  width: 300px;
  background-color: #fff;
}
</style>
