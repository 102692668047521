<template>
  <div>
    <div style="margin: 20px 0;">
      <span class="btn-class" :class="btnActive === 1 ? 'active' : ''" @click="handleChangeActive(1)">创建一个新账号</span>
      <span class="btn-class" :class="btnActive === 2 ? 'active' : ''" @click="handleChangeActive(2)">绑定原有账号</span>
    </div>
    <el-form v-if="btnActive === 1" :model="passForm" :rules="passRules" ref="passForm">
      <el-form-item prop="userName">
        <el-input placeholder="请输入用户名（支持中文、英文、数字，4-20个字符）" v-model="passForm.userName"></el-input>
      </el-form-item>
      <el-form-item prop="phoneNo">
        <el-input placeholder="请输入手机号码" maxlength='11'  v-model="passForm.phoneNo"></el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-input placeholder="短信验证码" v-model="passForm.code">
          <template #append>
            <el-link type="primary" class="a-link" style="text-decoration: none;" href="javascript:void(0)" :disabled="!isCodeEnable" @click="startCountdown">
              获取验证码
            </el-link>
            <vue-countdown :time="time" v-slot="{ seconds }" v-if="counting" :autoStart="true"
                           @end="onCountdownEnd">
              （{{ seconds }} 秒）
            </vue-countdown>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input placeholder="请输入密码" show-password v-model="passForm.password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button :loading="passFormLoading" type="primary" class="login-btn" @click="handlePassConfirm('passForm')"
                   :disabled="!passForm.userName || !passForm.password || !passForm.code || !passForm.phoneNo">
          创建并绑定
        </el-button>
      </el-form-item>
    </el-form>
    <el-form v-if="btnActive === 2" :model="passFormLogin" :rules="passRulesLogin" ref="passFormLogin">
      <el-form-item prop="userName">
        <el-input placeholder="请输入用户名" v-model="passFormLogin.userName"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input placeholder="请输入密码" show-password v-model="passFormLogin.password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button :loading="passFormLoginLoading" type="primary" class="login-btn" @click="handlePassConfirmLogin('passFormLogin')"
                   :disabled="!passFormLogin.userName || !passFormLogin.password">
          立即绑定
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  getIp,
  getByTel,
  getCompanyInfoByCode,
  getUserInfoByIdToken
} from '@/api/user.js'
import { ElMessage } from 'element-plus'
import { forwardBID } from '@/api/forward.js'
import VueCountdown from '@chenfengyuan/vue-countdown'
export default {
  name: 'login-new-user',
  components: { VueCountdown },
  props: [
    'openid',
    'unionId',
    'redirectPath'
  ],
  data () {
    var validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        const patrn = /^((\w)|[!@#$%^&*?]){6,20}$/
        if (!patrn.exec(value)) {
          return callback(new Error('只能输入6-20个字母、数字、特殊字符（!@#$%^&*）'))
        }
        callback()
      }
    }
    var validatePhoneNo = (rule, value, callback) => {
      if (!value) {
        this.isCodeEnable = false
        return callback(new Error('手机号码不能为空！'))
      }
      const regex = /^1[3456789]\d{9}$/
      if (!regex.test(value)) {
        this.isCodeEnable = false
        callback(new Error('请输入正确的手机号码！'))
      } else {
        this.isCodeEnable = true
        callback()
      }
    }
    return {
      passFormLoginLoading: false,
      passFormLoading: false,
      btnActive: 1,
      passRules: {
        userName: [{
          required: true,
          message: '用户名不能为空！',
          trigger: 'change'
        }],
        phoneNo: [
          {
            required: true,
            validator: validatePhoneNo,
            trigger: 'change'
          }
        ],
        code: [
          {
            required: true,
            message: '请输入手机验证码！',
            trigger: 'change'
          }
        ],
        password: [
          {
            required: true,
            validator: validatePassword,
            trigger: 'change'
          },
          {
            min: 6,
            max: 20,
            message: '长度在 6 到 20 个字符',
            trigger: 'blur'
          }
        ]
      },
      passRulesLogin: {
        userName: [{
          required: true,
          message: '用户名不能为空！',
          trigger: 'change'
        }],
        password: [{
          required: true,
          message: '密码不能为空',
          trigger: 'change'
        }]
      },
      passFormLogin: {
        userName: '',
        password: ''
      },
      passForm: {
        code: '',
        phoneNo: '',
        userName: '',
        password: '',
        isAgree: false
      },
      time: 60 * 1000,
      counting: false, // 倒计时
      isCodeEnable: false, // 验证码获取功能是否可用
      userInfo: null,
      mobile: '',
      loading: false
    }
  },
  methods: {
    handleChangeActive (e) {
      this.btnActive = e
    },
    // 获取验证码
    startCountdown () {
      this.isCodeEnable = false
      this.counting = true
      this.getVerifyCode()
    },
    // 发送验证码
    getVerifyCode () {
      const data = {
        dateBackgroundUrl: '/bid/verifyCode/registergetVerifyCode',
        dateBackgroundRequestType: 'post',
        data: {
          mobile: this.passForm.phoneNo,
          verifyTypeCode: '32'
        }
      }
      forwardBID(data)
        .then(result => {
        })
        .catch(error => {
          console.log(error)
        })
    },
    // 倒计时
    onCountdownEnd () {
      this.time = 60 * 1000
      this.counting = false
      this.isCodeEnable = true
    },
    handlePassConfirm (formName) {
      const that = this
      that.passFormLoading = true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          that.$store.dispatch('logout')
          let data = {
            dateBackgroundUrl: '/user/user/userRegister', // 用户名密码登录
            dateBackgroundRequestType: 'post',
            data: {
              mobile: that.passForm.phoneNo,
              password: that.passForm.password,
              enterpriseTypeCode: '1',
              loginName: that.passForm.userName,
              unionId: that.unionId,
              verification: that.passForm.code
            }
          }
          forwardBID(data).then(result => {
            if (result.code === 0) {
              data = {
                dateBackgroundUrl: '/user/user/login', // 用户名密码登录
                dateBackgroundRequestType: 'post',
                data: {
                  loginName: that.passForm.userName,
                  password: that.passForm.password
                }
              }
              forwardBID(data).then(result => {
                if (result.code === 0) {
                  const idToken = result.data.idToken
                  if (idToken) {
                    that.$store.dispatch('setIdToken', idToken)
                  }
                  // 通过tokenId获取用户信息，有则返回，没有则新增
                  getUserInfoByIdToken({
                    idToken: idToken,
                    openId: that.openid,
                    unionId: that.unionId
                  }).then(result => {
                  }).catch(error => {
                    that.passFormLoading = false
                    ElMessage.error({ message: error, offset: 60 })
                  })
                  const data = {
                    dateBackgroundUrl: '/bid/user/accountInfo', // 获取用户信息
                    dateBackgroundRequestType: 'post',
                    data: {
                      idToken: idToken
                    }
                  }
                  forwardBID(data)
                    .then(result => {
                      if (result.code === 0) {
                        const resultData = result.data
                        that.userInfo = resultData
                        that.mobile = resultData.mobile
                        const params = {
                          tel: that.mobile
                        }
                        getByTel(params).then(result => {
                          if (result.code === 0) {
                            if (result.data.enableStatus === 0) {
                              that.saveUserInfoToSession()
                              ElMessage.success({ message: '登录成功！', offset: 60 })
                              if (!that.redirectPath) {
                                // 路径为空不跳转，直接关闭
                                // this.$router.push({ name: 'index' })
                              } else {
                                that.passFormLoading = false
                                const path = that.redirectPath
                                this.$router.push({ path: path })
                              }
                            } else {
                              that.$store.dispatch('logout')
                              that.isLogin = false
                              that.$router.push({ name: 'index' })
                              that.$alert('<span class="icon iconfont" style="font-size: 23px;color: orange;vertical-align: middle;">&#xe713;</span>  您的账号已经被冻结.请联系客服 <span style="color: #5D6FE9;">400-8885449</span>', '温馨提示', {
                                dangerouslyUseHTMLString: true,
                                confirmButtonText: '确定'
                              })
                            }
                          } else {
                            resultData.openId = ''
                          }
                          that.passForm.phoneNo = ''
                          that.passForm.code = ''
                          that.passForm.userName = ''
                          that.passForm.password = ''
                          that.passForm.isAgree = false
                          that.$refs.passForm.resetFields()
                          that.$emit('closeLoginEmit')
                        }).catch(error => {
                          console.log(error)
                        })
                      } else {
                        that.passFormLoading = false
                        ElMessage.error({ message: result.msg, offset: 60 })
                      }
                    })
                }
              }).catch(error => {
                that.passFormLoading = false
                console.log(error)
              })
            } else {
              that.passFormLoading = false
              ElMessage.error({ message: result.msg, offset: 60 })
            }
          }).catch(error => {
            that.passFormLoading = false
            console.log(error)
          })
        } else {
          return false
        }
      })
    },
    handlePassConfirmLogin (formName) {
      const that = this
      that.passFormLoginLoading = true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          that.$store.dispatch('logout')
          let data = {
            dateBackgroundUrl: '/user/user/login', // 用户名密码登录
            dateBackgroundRequestType: 'post',
            data: {
              loginName: that.passFormLogin.userName,
              password: that.passFormLogin.password,
              unionId: that.unionId
            }
          }
          forwardBID(data).then(result => {
            if (result.code === 0) {
              data = {
                dateBackgroundUrl: '/user/user/login', // 用户名密码登录
                dateBackgroundRequestType: 'post',
                data: {
                  loginName: that.passFormLogin.userName,
                  password: that.passFormLogin.password
                }
              }
              forwardBID(data).then(result => {
                if (result.code === 0) {
                  const idToken = result.data.idToken
                  if (idToken) {
                    that.$store.dispatch('setIdToken', idToken)
                  }
                  // 通过tokenId获取用户信息，有则返回，没有则新增
                  getUserInfoByIdToken({
                    idToken: idToken,
                    openId: that.openid,
                    unionId: that.unionId
                  }).then(result => {
                  }).catch(error => {
                    that.passFormLoginLoading = false
                    ElMessage.error({ message: error, offset: 60 })
                  })
                  const data = {
                    dateBackgroundUrl: '/bid/user/accountInfo', // 获取用户信息
                    dateBackgroundRequestType: 'post',
                    data: {
                      idToken: idToken
                    }
                  }
                  forwardBID(data)
                    .then(result => {
                      if (result.code === 0) {
                        const resultData = result.data
                        that.userInfo = resultData
                        that.mobile = resultData.mobile
                        const params = {
                          tel: that.mobile
                        }
                        getByTel(params).then(result => {
                          if (result.code === 0) {
                            if (result.data.enableStatus === 0) {
                              that.saveUserInfoToSession()
                              ElMessage.success({ message: '登录成功！', offset: 60 })
                              if (!that.redirectPath) {
                                // 路径为空不跳转，直接关闭
                                // this.$router.push({ name: 'index' })
                              } else {
                                that.passFormLoginLoading = false
                                const path = that.redirectPath
                                this.$router.push({ path: path })
                              }
                            } else {
                              that.$store.dispatch('logout')
                              that.isLogin = false
                              that.$router.push({ name: 'index' })
                              that.$alert('<span class="icon iconfont" style="font-size: 23px;color: orange;vertical-align: middle;">&#xe713;</span>  您的账号已经被冻结.请联系客服 <span style="color: #5D6FE9;">400-8885449</span>', '温馨提示', {
                                dangerouslyUseHTMLString: true,
                                confirmButtonText: '确定'
                              })
                            }
                          } else {
                            resultData.openId = ''
                          }
                          that.passFormLogin.userName = ''
                          that.passFormLogin.password = ''
                          that.$refs.passFormLogin.resetFields()
                          that.$emit('closeLoginEmit')
                        }).catch(error => {
                          console.log(error)
                        })
                      } else {
                        that.passFormLoginLoading = false
                        ElMessage.error({ message: result.msg, offset: 60 })
                      }
                    })
                }
              }).catch(error => {
                that.passFormLoginLoading = false
                console.log(error)
              })
            } else {
              that.passFormLoginLoading = false
              ElMessage.error({ message: result.msg, offset: 60 })
            }
          }).catch(error => {
            that.passFormLoginLoading = false
            console.log(error)
          })
        } else {
          return false
        }
      })
    },
    // 用户信息存放缓存中
    saveUserInfoToSession () {
      const that = this
      const resultData = that.userInfo
      getIp()
        .then(result => {
          if (result.code === 0) {
            const arr = result.data.split(',')
            resultData.ip = arr[0]
          } else {
            resultData.ip = '127.0.0.1'
          }
          that.$store.dispatch('setUserInfo', JSON.stringify(resultData))
        })
        .catch(error => {
          console.log(error)
        })
      const params = {
        tel: that.mobile
      }
      getByTel(params)
        .then(result => {
          if (result.code === 0) {
            resultData.openId = result.data.openId
            that.$store.dispatch('setVipData', JSON.stringify(result.data))
          } else {
            resultData.openId = ''
          }
          that.$store.dispatch('setUserInfo', JSON.stringify(resultData))
        })
        .catch(error => {
          console.log(error)
        })
      if (resultData.taxpayerNumber) {
        getCompanyInfoByCode({
          code: resultData.taxpayerNumber
        }).then(result => {
          const res = result.data
          resultData.companyId = res.data?.id
          that.$store.dispatch('setUserInfo', JSON.stringify(resultData))
        }).catch(error => {
          that.passFormLoginLoading = false
          that.passFormLoading = false
          console.log(error)
        })
      }
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.btn-class {
  border: 1px solid #ebebeb;
  border-radius: 4px;
  padding: 8px 33px;
  cursor: pointer;
}
.btn-class.active {
  background-color: #5D6FE9;
  color: #fff;
}
.btn-class.active:hover {
  color: #fff;
}
.btn-class:hover {
  color: #5D6FE9;
}
.el-form-item {
  margin-bottom: 18px;
}
.a-link:hover{text-decoration:overline; cursor:pointer}
</style>
